<template>
  <div class="">
  </div>
</template>

<script>
  import { getParamsFromUrl } from '@/helper/UrlHelper'
  import { apiLoginViaCode } from '@/api/user/ApiUserLogin'
  import { setAccessToken } from '@/service/user/UserProfileService'
  import {Message} from "@/constant/beecost/BeeCostResourceConstant";

  export default {

    data() {
      return {}
    },
    async mounted() {
      let url = document.location.href
      const { state, code } = getParamsFromUrl(url)

      if (code?.length > 0 && state != null) {
        let response = await apiLoginViaCode(state, code)
        if (response != null) {
          let accessToken = response['access_token']
          if (accessToken?.length > 0) {
            await setAccessToken(accessToken)
            if (window.opener) {
              window.opener.postMessage(Message.USER_LOGGED_IN, '*')
              // window.location.reload()
            } else {
              console.warn('can not load opener')
            }
          }
        }
      }

    }
  }
</script>

<style lang="scss">

</style>
