import { getLoginUrl } from '@/api/user/UserEndpoint'
import { http } from '@/plugin/http'

const apiLoginViaCode = async (state, code, platform = 2) => {
  let urlApiLoginViaCode = getLoginUrl(state)
  try {
    let response = await http.post(urlApiLoginViaCode, {
      'code': code,
      'platform': platform,
    })

    if (response?.data) {
      return response.data
    }
  } catch (e) {
    console.error(e)
  }

  return null
}

export {
  apiLoginViaCode
}
